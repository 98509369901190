.header-container{
	background-color: #fff;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
  width: 100%;
  /* margin-top: 1.25rem; */
  margin-right: 0.5rem;
}

.header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
  width: 100%;
  border-bottom: 1px solid #e4e4e4;
  padding: 0.5rem 0.75rem;
}

.content-container{
	display: flex;
	flex-direction: row;
  align-items: center;
}

.users{
  display: flex;
  flex-direction: row;
}

.user{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.25rem 0.75rem;
  background-color: #eeeeee;
  border-radius: 30px;
  margin: 0 0.5rem;
  cursor: pointer;
}
.user:hover{
  background-color: #f5f5f5;
}

.x-icon{
  border: none;
  background-color: transparent;
  margin-left: 0.5rem;
  font-size: 16px;
}

/* .create-channel-button{
  border: none;
  padding: 0.75rem 1.2rem;
  color: #FFF;
  background-color: var(--primary-color);
  border-radius: 24px;
  font-size: 15px;
  font-weight: 600;
  transition: 200ms;
  margin: 0.25rem 0 0.5rem 0.25rem;
}

.create-channel-button:hover{
  background-color: hsl(203, 80%, 29%);
  cursor: pointer;
} */

.create-channel-button{
  border: none;
  /* background-color: var(--primary-color); */
  background-color: #fff;
  transition: 200ms;
  border-radius: 50%;
  margin: 0.25rem 0 0.5rem 0.75rem;
  box-shadow: 0 4px 12px 0 hsl(220, 15%, 88%), 0 3px 9px 0 hsl(220, 15%, 98%);
}

.create-channel-button:hover{
  background-color: hsl(203, 80%, 29%);
  cursor: pointer;
}

.create-channel-button:hover svg path{
  stroke: #fff;
}


.user-name-input{
	background-color: transparent;
	border: none;
	outline: none;
	font-size: 16px;
  margin-left: 0.5rem;
  padding: 0.5rem 0.75rem;
  width: 150px;
}

.user-list {
  display: flex;
  flex-direction: column;
  max-width: 330px;
  background: #f1f1f1;
  /* opacity: 0.9; */
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  overflow-y: auto;
  list-style: none;
  padding: 0;
  margin-left: 4px;
  margin-top: 4px;
}

.user-list-item{
  /* max-width: 330px; */
  background: #f7f7f7;
  /* opacity: 0.9; */
  overflow-y: auto;
}

.user-result{
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-y: auto;
  list-style: none;
  padding: 0.5rem;
  cursor: pointer;
}

.user-list-item.focused {
  background: #ffffff;
}

.no-results{
  padding: 0.5rem;
  background: #f7f7f7;
}

.avatar {
	display: flex;
	align-items: center;
	font-size: 1.25rem;
	height: 40px;
	min-width: 40px;
	max-width: 40px;
	border-radius: 20px;
	overflow: hidden;
	background-color: #9499de;
	color: #fff;
	justify-content: center;
	margin-right: 10px;
}

.mobile-cancel {
  display: none;
}

@media screen and (max-width: 640px) {
.desktop-cancel{
  display: none;
}
.mobile-cancel {
  display: block;
}

.create-channel-button{

  margin: 0.25rem auto;
}

}