.list-container {
    min-width: 350px;
    height: 100%;
    overflow-y: auto;
}
.header-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 1rem 2rem 1.75rem;
}

.user-info{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.add-channel-icon{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #FFF;
    border: none;
    box-shadow: 0 4px 12px 0 hsl(220, 15%, 88%), 0 3px 9px 0 hsl(220, 15%, 98%);
    cursor: pointer;
    transition: 200ms;
}

.add-channel-icon:hover{
    box-shadow:  0 3px 9px 0 hsl(220, 15%, 98%),0 4px 12px 0 hsl(220, 15%, 88%);
    background-color: var(--primary-color);
}

.add-channel-icon:hover svg path{
    fill: #fff;
}

.add-channel-icon:active {
    background-color:hsl(202.782, 79.79%, 95%);
    box-shadow:  0 3px 9px 0 hsl(220, 15%, 98%),0 4px 12px 0 hsl(220, 15%, 88%);
}

.container{
	display: flex;
	flex-direction: column;
	padding: 0.6rem 0.75rem;
	margin: 0.4rem 1rem;
    background-color: #fefefe;
	box-shadow: 0 5px 13px 0 hsl(220, 15%, 94%), 0 3px 9px 0 hsl(220, 15%, 94%);
	border-radius: 8px;
	cursor: pointer;
}

.container p:first-of-type{
    font-weight: 600;
}


.overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 100;
	background: hsla(203, 80%, 29%, 0.3);
}

.modal {
	position: fixed;
	width: 350px;
	height: 150px;
	z-index: 200;
	top: calc((100% - 150px)/2);
	left: calc((100% - 350px)/2);
	background-color: #fff;
	padding: 1rem;
	border-radius: 8px;
}

.modal-header{
	margin-bottom: 2.25rem;
}

.show {
	display: block;
}

.hide {
	display: none;
}

.off {
	transform: scale(0);
}

.on {
	transform: scale(1);
	transition: 200ms;
}

.buttons-container{
	display: flex;
	justify-content: flex-end;
	margin-top: 1rem;;
}

.button{
	border: none;
	padding: 0.75rem 1.2rem;
	color: #FFF;
	border-radius: 24px;
	font-size: 15px;
	font-weight: 600;
	transition: 200ms;
	margin-left: 0.5rem;  
	cursor: pointer;
}

.cancel {
	background-color: hsla(203, 80%, 29%);
}

.confirm {
	background-color:hsla(353, 70%, 58%);
}

.cancel:hover {
	background-color: hsla(203, 80%, 39%);
}

.confirm:hover {
	background-color:hsla(353, 70%, 68%);
}


/* Skeleton Loader */

.skeleton-loader__list {
	position: absolute;
	width: 260px;
	height: 100%;
	float: left;
	margin-top: 0;
	padding: 0;
	list-style: none;
  }
  
  @media screen and (max-width: 640px) {
	.skeleton-loader__list {
	  width: unset;
	}
  }
  
  .skeleton-loader__list li {
	display: flex;
	align-items: center;
	margin-bottom: 24px;
  }
  
  .skeleton-loader__avatar,
  .skeleton-loader__text {
	display: inline-block;
  }
  
  .skeleton-loader__avatar {
	float: left;
	width: 40px;
	height: 40px;
	margin-right: 10px;
	border-radius: 100px;
  }
  
  .skeleton-loader__text div {
	width: 200px;
	height: 12px;
	border-radius: 20px;
  }
  
  .skeleton-loader__text div:nth-child(2) {
	height: 6px;
	margin-top: 8px;
  }
  
  .skeleton-loader__avatar,
  .skeleton-loader__text div {
	animation: placeHolderShimmer 1.5s linear infinite;
	background: rgba(255, 255, 255, 0.2);
	background-image: linear-gradient(
	  to right,
	  rgba(0, 0, 0, 0) 0%,
	  rgba(0, 0, 0, 0) 5%,
	  rgba(0, 0, 0, 0.2) 25%,
	  rgba(0, 0, 0, 0.25) 50%,
	  rgba(0, 0, 0, 0.2) 75%,
	  rgba(0, 0, 0, 0) 95%,
	  rgba(0, 0, 0, 0) 100%
	);
	background-repeat: no-repeat;
	background-size: 400px 100%;
  }
  
  @keyframes placeHolderShimmer {
	0% {
	  background-position: -400px 0;
	}
	100% {
	  background-position: 400px 0;
	}
  }