*{
	padding:0;
	margin:0;
	box-sizing: border-box;
}

html{
  font-size: 16px;
  line-height: 1.5;
}

*::after,
*::before{
  box-sizing: inherit;
}

ul,li{
  list-style-type: none;
}

a{
  text-decoration: none;
}

:root{
  --primary-color: #0a3b59;
  --accent-color: #9499de;
}

body::-webkit-scrollbar {
  background: transparent;
}

body {
  scrollbar-color: transparent transparent;
}

body::-webkit-scrollbar-track {
  background: transparent;
}

body::-webkit-scrollbar-thumb {
  background-color: transparent;
}

#root {
  height: 100%;
}

.str-chat.str-chat-channel.messaging {
  background: #fafafa;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.06), 0px 2px 30px rgba(0, 0, 0, 0.1);
}

.dark.str-chat.str-chat-channel.messaging {
  background: #212326;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.1);
}

.str-chat.str-chat-channel-list.messaging {
  background: #fafafa;
}

.dark.str-chat.str-chat-channel-list.messaging {
  background: #212326 !important;
}

/* .str-chat-channel .str-chat__container {
  padding: 30px 30px 0 0;
} */

.str-chat-channel.messaging .str-chat__main-panel {
  padding: 0 !important;
}

.messaging.str-chat .str-chat__list .str-chat__reverse-infinite-scroll {
  padding-top: 0;
}

.messaging.str-chat.dark .str-chat__list {
  padding: 0 30px 0;
  background: #282a2d;
}

.str-chat-channel.messaging .str-chat__main-panel {
  padding: 30px 30px 0 0px;
}

.str-chat-channel.messaging .str-chat__main-panel:not(:last-child) {
  padding: 20px 0 0 0px;
}

.str-chat-channel.messaging .str-chat__main-panel:not(:last-child) .messaging__channel-header {
  border-radius: 10px 0px 0px 0px;
}

.str-chat__message-simple-status {
  display: none;
}

.messaging.str-chat.dark .str-chat__message--system__date {
  color: rgba(255, 255, 255, 0.7);
}

.messaging.str-chat.dark .str-chat__message--system__text p {
  color: rgba(255, 255, 255, 0.7);
}

.messaging.str-chat.dark .str-chat__message--system__line {
  background: rgba(255, 255, 255, 0.1);
}

.str-chat__message--system {
  padding: 20px;
}

.str-chat__avatar-fallback{
  background-color: var(--accent-color);
}

.str-chat__main-panel{
  /* max-height: 98vh; */
  margin-top: auto;

}

.str-chat-channel{
  width: 100%;
}

.container{
  display: flex; 
  flex: 1;
  height: 100vh;
}

.messaging__sidebar {
	height: 100%;
	display: grid;
	grid-template-rows: min-content;
	float: left;
  }

.str-chat__message-team-actions{
  width: 125px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.str-chat__message-team-actions svg{
  height: 16px;
  width: 16px;
}

.str-chat__message-team-actions span:nth-child(2) {
  margin-top: 5px
}

.str-chat__message-team-actions span {
  margin: 0
}

.loading-indicator-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

/* Mobile View */
@media screen and (max-width: 640px) {
  /*
   * This is to prevent the browser navigation bars from hiding the app
   * on some mobile browsers. The app-height variable is set dynamically
   * using javascript.
   */
  .str-chat-channel {
    height: var(--app-height);
  }

  .str-chat-channel-list.messaging {
    float: unset;
  }

  #mobile-channel-list {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    transform: translate3d(-100vw, 0, 0);
    transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
  }

  #mobile-channel-list.show {
    transform: translate3d(0vw, 0, 0);
    z-index: 1000;
  }
}


/* To fix inherited styles (as per Team and Customer Support apps */
@media screen and (max-width: 960px) {
  .str-chat-channel-list.messaging {
    position: unset;
    left: unset;
    top: unset;
    z-index: unset;
    min-height: unset;
    overflow-y: unset;
    box-shadow: unset;
    transition: unset;
  }
}
