.header-container{
	padding: 1rem;
	background-color: #fff;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	border-bottom: 1px solid #e4e4e4;
}

.channel-name{
	font-weight: 600;
}

.channel-name-input{
	background-color: transparent;
	border: none;
	outline: none;
	font-size: 16px;
	padding: 0.5rem 0.75rem;
	min-width: 400px;
	max-width: 550px;
}

.content-container{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.left{
	display: flex;
	align-items: center;
}

.actions{
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.add-user{
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: #FFF;
	border: none;
	box-shadow: 0 4px 12px 0 hsl(220, 15%, 88%), 0 3px 9px 0 hsl(220, 15%, 98%);
	margin-left: 1rem;
	font-size: 32px;
	color: var(--primary-color) ;
	cursor: pointer;
	transition: 200ms;
	text-align: center;
}

.add-user:hover{
	box-shadow:  0 3px 9px 0 hsl(220, 15%, 98%),0 4px 12px 0 hsl(220, 15%, 88%);
	background-color: var(--primary-color) ;
	color: #FFF;
}

.add-user:hover svg{
	fill: #FFF;
}

.add-user:active {
	background-color:hsl(202.782, 79.79%, 95%);
	box-shadow:  0 3px 9px 0 hsl(220, 15%, 98%),0 4px 12px 0 hsl(220, 15%, 88%);
}

.leave{
	border: none;
	padding: 0.75rem 1.2rem;
	color: #FFF;
	background-color: var(--primary-color);
	border-radius: 24px;
	font-size: 15px;
	font-weight: 600;
	transition: 200ms;
	margin-left: 0.5rem;
}

.avatars {
  display: flex;
  align-items: center;
  height: 40px;
  min-width: 40px;
  max-width: 40px;
  border-radius: 20px;
  overflow: hidden;
  margin-right: 10px;
}


.avatar {
	display: flex;
	align-items: center;
	font-size: 1.25rem;
	height: 40px;
	min-width: 40px;
	max-width: 40px;
	overflow: hidden;
	background-color: #9499de;
	color: #fff;
	justify-content: center;
}

.avatar.three,
.avatar.four {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding-left: 3px;
	height: 20px;
	width: 20px;
}

.avatars.three .first{
	height: 40px;
	width: 40px;
}
  

.avatars.two .avatar:first-child {
  position: relative;
  right: 11px;
}

.avatars.two .avatar:nth-child(2) {
  position: relative;
  left: 10px;
}

.avatars.two span, 
.avatars.three span,
.avatars.four span {
  width: 20px;
  overflow: hidden;
}

.avatar-half-center img{
	position: relative;
	right: 9px; 
}

.members-list-container{
	padding: 0.5rem 2rem 0.5rem 1rem;
	position: absolute;
	background-color: #FFF;
	border-radius: 4px;
	box-shadow:  0 3px 9px 0 hsl(220, 15%, 98%),0 4px 12px 0 hsl(220, 15%, 88%);
	z-index: 10000;
}

.members-list-container p{
	padding: 0.2rem 0;
}

.overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 100;
	background: hsla(203, 80%, 29%, 0.3);
}

.modal {
	position: fixed;
	width: 350px;
	height: 150px;
	z-index: 200;
	top: calc((100% - 150px)/2);
	left: calc((100% - 350px)/2);
	background-color: #fff;
	padding: 1rem;
	border-radius: 8px;
}

.modal-header{
	margin-bottom: 2.25rem;
}

.show {
	display: block;
}

.hide {
	display: none;
}

.off {
	transform: scale(0);
	/* transition: 200ms; */
}

.on {
	transform: scale(1);
	transition: 200ms;
}

.buttons-container{
	display: flex;
	justify-content: flex-end;
	margin-top: 1rem;;
}

.button{
	border: none;
	padding: 0.75rem 1.2rem;
	color: #FFF;
	border-radius: 24px;
	font-size: 15px;
	font-weight: 600;
	transition: 200ms;
	margin-left: 0.5rem;  
	cursor: pointer;
}

.cancel {
	background-color: hsla(203, 80%, 29%);
}

.confirm {
	background-color:hsla(353, 70%, 58%);
}

.cancel:hover {
	background-color: hsla(203, 80%, 39%);
}

.confirm:hover {
	background-color:hsla(353, 70%, 68%);
}


#mobile-nav-icon {
  display: none;
}

@media screen and (max-width: 640px) {
  #mobile-nav-icon {
    display: block;
    padding: 0 0.5rem;
  }
}