.edit {
	cursor: pointer;
	transition-duration: 200ms;
}

.edit:hover {
	fill: #9499DE;
}

.confirm{
	margin-bottom: -1rem;
}

.confirm:hover{
	cursor: pointer;
	transition-duration: 200ms;
}

.confirm:hover path{
	fill: var(--accent-color);
	transition: all ease 0.3s;
}
