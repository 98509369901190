.invite-container{
	display: flex;
	flex-direction: column;
	padding: 0.6rem 0.75rem;
	margin: 0.4rem 1rem;
	cursor: pointer;
	background-color: #fefefe;
	box-shadow: 0 5px 13px 0 hsl(220, 15%, 94%), 0 3px 9px 0 hsl(220, 15%, 94%);
	border-radius: 8px;
}

.invite-container p:first-of-type{
    font-weight: 600;
}


.container{
	display: flex;
	flex-direction: row;
	padding: 0.6rem 0.75rem;
	margin: 0 1rem;
	cursor: pointer;
	border-radius: 5px;
	max-width: 350px;
}

.container-inactive{
	border-bottom: 1px solid #e7e7e7;
}
.container-inactive:hover{
	background-color: rgba(255,255,255, 0.8);
	border-radius: 8px;
}

.container-active{
	background-color: #e0eeff;
	box-shadow: 0 5px 13px 0 hsl(220, 15%, 94%), 0 3px 9px 0 hsl(220, 15%, 94%);
	border-radius: 8px;
}

.has-unread{
	background-color: #fff;
}

.info{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: space-between; 
	width: 100%;
}

.circle{
	background-color: #ff5b4d;
	border-radius: 50%;
	height: 14px;
	width: 14px;
	display: none;
}

.row{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.title{
	font-size: 14px;
	max-width: 150px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.time{
	font-size: 12px;
	color: #858688;
	margin-left: auto;
}

.message{
	font-size: 13px;
	color: #858688;
	max-width: 165px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}


.avatars {
	display: flex;
	align-items: center;
	height: 40px;
	min-width: 40px;
	max-width: 40px;
	border-radius: 20px;
	overflow: hidden;
	margin-right: 10px;
}

.avatar {
	display: flex;
	align-items: center;
	font-size: 1.25rem;
	height: 40px;
	min-width: 40px;
	max-width: 40px;
	overflow: hidden;
	background-color: #9499de;
	color: #fff;
	justify-content: center;
}

.avatar.three,
.avatar.four {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding-left: 3px;
	height:20px;
	width: 20px;
}

.avatars.three .first{
	height:40px;
	width: 40px;
}

.avatars.two .avatar:first-child {
  position: relative;
  right: 11px;
}

.avatars.two .avatar:nth-child(2) {
  position: relative;
  left: 10px;
}

.avatars.two span, 
.avatars.three span,
.avatars.four span {
  width: 20px;
  overflow: hidden;
}

.avatar-half-center img{
	position: relative;
	right: 9px; 
}



.overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 100;
	background: hsla(203, 80%, 29%, 0.3);
}

.modal {
	position: fixed;
	width: 350px;
	height: 150px;
	z-index: 200;
	top: calc((100% - 150px)/2);
	left: calc((100% - 350px)/2);
	background-color: #fff;
	padding: 1rem;
	border-radius: 8px;
}

.modal-header{
	margin-bottom: 2.25rem;
}

.show {
	display: block;
}

.hide {
	display: none;
}

.off {
	transform: scale(0);
}

.on {
	transform: scale(1);
	transition: 200ms;
}

.buttons-container{
	display: flex;
	justify-content: flex-end;
	margin-top: 1rem;
}

.button{
	border: none;
	padding: 0rem 0rem 0 1.25rem;
	color: #FFF;
	font-size: 15px;
	font-weight: 600;
	transition: 200ms;
	cursor: pointer;
	background-color: transparent;
}

.cancel {
	color: hsla(203, 80%, 29%);
}

.confirm {
	color:hsla(353, 70%, 58%);
}

.cancel:hover {
	color: hsla(203, 80%, 39%);
}

.confirm:hover {
	color:hsla(353, 70%, 68%);
}